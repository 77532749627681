<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline>
                    <el-row>
                        <el-col v-bind="search_col_attrs"><el-form-item label="用户ID:" prop="id"><el-input clearable size="mini" v-model="search_option.id"></el-input></el-form-item></el-col>
                        <el-col v-bind="search_col_attrs"><el-form-item label="会员编号:" prop="user_no"><el-input clearable size="mini" v-model="search_option.user_no"></el-input></el-form-item></el-col>
                        <el-col v-bind="search_col_attrs"><el-form-item label="昵称:" prop="nick_name"><el-input clearable v-model="search_option.nick_name"></el-input></el-form-item></el-col>
                        <el-col v-bind="search_col_attrs"><el-form-item label="备注:" prop="remark"><el-input clearable v-model="search_option.remark"></el-input></el-form-item></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                      <el-col :span="12" align="right">
                        <el-button type="primary" icon="el-icon-tickets" size="mini" @click="onExportTap('page')">导出当页</el-button>
                        <el-button type="primary" icon="el-icon-tickets" size="mini" @click="onExportTap('all')">导出全部</el-button>
                      </el-col>
                    </el-row>
                </el-form>
            </div>

            <div style="margin-top: 16px;">
                <el-table :data="page_list" v-loading="loading" size="mini" border @sort-change="handleSortChange">
                    <!--这里改成会员ID-->
                  <el-table-column label="ID" prop="id" width="120" show-overflow-tooltip sortable="custom"></el-table-column>
                  <el-table-column label="会员编号" prop="user_no" width="120" show-overflow-tooltip sortable="custom"></el-table-column>
                    <el-table-column label="OpenId" prop="open_id" width="120" show-overflow-tooltip sortable="custom"></el-table-column>
                    <el-table-column label="头像" prop="avatar" width="49">
                      <template #default="scope">
                        <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
                      </template>
                    </el-table-column>
                  <el-table-column label="昵称" prop="nick_name" show-overflow-tooltip width="100"></el-table-column>
                  <el-table-column label="注册时间" prop="created_at" width="136" :formatter="elTimeStampFormat" sortable="custom"></el-table-column>
                  <el-table-column label="登陆次数" prop="logins" width="72" align="center"></el-table-column>
                  <el-table-column label="折扣状态" prop="discount_enable" width="72" align="center">
                    <template #default="scope">
                      <div v-if="scope.row.discount_enable" style="color:#67C23A;font-weight: bold">启用中</div>
                      <div v-else style="color: #F56C6C;font-weight: bold">未启用</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="折扣次数" prop="discount_times" width="100" align="center" sortable="custom"></el-table-column>
                  <el-table-column label="折扣计算" width="72" align="center">
                    <template #default="scope">
<!--                      <div :style="params_map['discount_operate'][scope.row.discount_operate]?'background: #67C23A;color: #ffffff;border-radius: 12px':'background: #F56C6C;color: #ffffff;border-radius: 12px'">-->
<!--                        <span style="margin-right: 12px;">{{ params_map['discount_operate'] ? (params_map['discount_operate'][scope.row.discount_operate] ? params_map['discount_operate'][scope.row.discount_operate].value : '') : '' }}</span>-->
<!--                        <span>{{ scope.row.discount_ratio }}</span>-->
<!--                      </div>-->
                      <div v-if="scope.row.discount_enable" style="background: #67C23A;color: #ffffff;border-radius: 12px">
                        <span style="margin-right: 12px;">{{ params_map['discount_operate'] ? (params_map['discount_operate'][scope.row.discount_operate] ? params_map['discount_operate'][scope.row.discount_operate].value : '') : '' }}</span>
                        <span>{{ scope.row.discount_ratio }}</span>
                      </div>
                      <div v-if="!scope.row.discount_enable" Style="background: #F56C6C;color: #ffffff;border-radius: 12px">
                        <span style="margin-right: 12px;">{{ params_map['discount_operate'] ? (params_map['discount_operate'][scope.row.discount_operate] ? params_map['discount_operate'][scope.row.discount_operate].value : '') : '' }}</span>
                        <span>{{ scope.row.discount_ratio }}</span>
                      </div>
                    </template>
                  </el-table-column>x
                  <el-table-column label="备注1" prop="remark_1"></el-table-column>
                  <el-table-column label="备注2" prop="remark_2"></el-table-column>
                  <el-table-column label="备注3" prop="remark_3"></el-table-column>
                  <el-table-column label="会员标签" prop="tag"></el-table-column>
                  <el-table-column label="操作" width="68">
                    <template #default="scope">
<!--                      <el-button type="text" plain size="mini" @click="onRowViewTap(scope.row)">详情</el-button>-->
                      <el-button type="text" size="mini" @click="onEditUserDetailTap(scope.row)">编辑</el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5, 10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>


        <el-dialog v-model="dialog_user_detail_visible" :title="dialog_user_detail_title" @close="onTellDialogClose" width="800px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <user-detail :row-id="dialog_user_detail_id"  mode="single" scene="send" @onTellDialogClose="onTellDialogClose"></user-detail>
        </el-dialog>

    </div>

</template>

<script>
import {getList} from "@/api/vstar/user";
import {getParams} from "@/api/vstar/param";
import UserDetail from "@/views/b/user/user-detail";
import {formatTimestamp} from "@/utils/time";
import {exportExcel} from "@/api/vstar/user";

const param_list = ['discount_operate']
export default {
  name: "user-list",
  components: {UserDetail},
  data() {
    return {
      loading: false,
      search_form_name: 'user_search',
      page_option: {page: 1, size: 10, total: 0},
      search_option: {id: '', user_no: '',nick_name:'',remark:''},
      page_list: [],

      params: {},
      params_map: {},
      params_map_reverse: {},

      dialog_user_detail_visible: false,
      dialog_user_detail_title: '用户信息',
      dialog_user_detail_id: '',

      search_col_attrs:Object.freeze({xs:24,sm:12,md:12,lg:6,xl:6}),
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    initPage() {
      this.initParams().then(()=>{
        this.getPageList({page: 1, size: this.page_option.size}, Object.assign({}, this.search_option))
      })
    },
    initParams(){
      return new Promise((resolve,reject)=>{
        getParams(param_list.join(','),'en').then(resp=>{
          this.params = resp.data;
          console.log('',this.params)
          let tmp_params_map = {}
          let tmp_params_map_pure = {}
          let tmp_params_map_reverse = {}

          //这里每组参数做成kv方便显示
          let p_key = Object.keys(this.params)
          p_key.forEach(pl=>{
            console.log('pl',pl)
            let tmp_map = {}
            let tmp_map_pure = {}
            let tmp_map_reverse = {}

            this.params[pl].forEach(pm=>{
              tmp_map_pure[pm.key]=pm.value
              tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}
              //再调转处理一下 导入Excel参数解析用
              tmp_map_reverse[pm.value]=pm.key
            })
            tmp_params_map[pl] = tmp_map
            tmp_params_map_pure[pl] = tmp_map_pure
            tmp_params_map_reverse[pl] = tmp_map_reverse
          })

          this.params_map = tmp_params_map
          this.params_map_pure = tmp_params_map_pure
          this.params_map_reverse = tmp_params_map_reverse
          resolve(resp)
        }).catch((err)=>{
          if(err){
            console.error(err)
          }
          reject(err)
        })
      })
    },
    reloadPage(){
      this.getPageList(this.page_option, this.search_option)
    },
    getPageList(pageOpt, searchOpt) {
      this.loading = true;
      getList(pageOpt.page, pageOpt.size, searchOpt).then(resp => {
        this.loading = false;
        this.page_list = resp.data.records;
        this.page_option.page = pageOpt.page;
        this.page_option.total = resp.data.total;
        this.page_option.size = pageOpt.size;
      }).catch(() => {
        this.loading = false;
      })
    },
    handleSearchTap() {
      let searchOpt = Object.assign({}, this.search_option)
      this.getPageList({page: 1, size: this.page_option.size}, searchOpt)
    },
    handleSearchClear(formName) {
      this.$refs[formName].resetFields();
      this.reloadPage()
    },
    handleSizeChange(pageSize) {
      this.getPageList({page: 1, size: pageSize}, this.search_option);
    },
    handleCurrentChange(pageIndex) {
      this.getPageList({page: pageIndex, size: this.page_option.size}, this.search_option)
    },
    // onRowViewTap(row) {
    //   console.log('onRowViewTap:', row)
    // },
    onEditUserDetailTap(row) {
      this.dialog_user_detail_visible = true
      this.dialog_user_detail_id = row.id
      this.dialog_user_detail_title = '用户Id:'+ row.id
    },
    onTellDialogClose() {
      this.dialog_user_detail_visible = false;
      this.dialog_user_detail_id = '';
      this.reloadPage()
    },
    elTimeStampFormat(row,column,val){
      return formatTimestamp(val)
    },
    // 网络排序
    handleSortChange(e){
      this.search_option.sort_field = e.prop;
      let sort_dct = {ascending:"asc",descending:"desc",}
      this.search_option.sort = sort_dct[e.order]
      this.initPage()
    },
    //导出
    onExportTap(export_type){
      let s_opt = Object.assign({}, this.search_option)
      let p_opt = Object.assign({},this.page_option)
      let params = Object.assign({},{export_type:export_type},s_opt,p_opt)
      console.log('params:',params)
      exportExcel(params).then(res=>{
        console.log('res:',res)
        const blob = new Blob([res.data]);//new Blob([res])中不加data就会返回下图中[objece objece]内容（少取一层）
        console.log('blob:',blob)
        if(res.data.type!=="application/force-download"){
          // console.log(res)
          console.log("文件类型不对:",blob.type)
          this.$message.warning("你无法下载订单")
          return
        }
        // const fileName = 'vstar订单.xlsx';//下载文件名称
        // let fileName = 'vstar订单.xlsx';//下载文件名称
        let fileName = this.getFileName(res)||'vstar订单.xlsx'
        const elink = document.createElement('a');
        elink.download = fileName;
        // elink.download = this.getFileName(res);
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).catch(err=>{
        this.$message.error("下载异常")
        console.log("catch:",err)
      })
    },
    getFileName( response) {
      let fileName = ''
      // 需要响应设置此header暴露给外部，才能获取到
      let contentDisposition = response.headers['content-disposition']
      if (contentDisposition) {
        // 正则获取filename的值
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/

        let matches = filenameRegex.exec(contentDisposition)
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '')
        }
        // 通过 URLEncoder.encode(pFileName, StandardCharsets.UTF_8.name()) 加密编码的, 使用decodeURI(fileName) 解密
        fileName = decodeURI(fileName)
        // 通过 new String(pFileName.getBytes(), StandardCharsets.ISO_8859_1) 加密编码的, 使用decodeURI(escape(fileName)) 解密
        // fileName = decodeURI(escape(fileName))
      }
      return fileName
    }
  }
}
</script>

<style scoped>

</style>
